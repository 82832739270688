.solicitud_tab {
	border-bottom: 2px solid var(--neutral_300);
	&.active {
		border-color: var(--primary_300);
	}
}

.pager_solicitudes {
	max-width: 30vw;
}
.center-button {
	display: block;
	margin: 0 auto;
}
.rejected-center-items {
	display: flex;
	align-items: center;
	border-style: dashed;
	border-color: lightgray;
	border-width: 5px;
	width: 300px;
	padding: 5px;
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 30px;
	border-radius: 10px;
	cursor: pointer;
}
.width_text_rejected {
	width: 100px;
}
.border_orange_rejected {
	border-color: #fe8a02 !important;
	border-style: solid !important;
}
.soli_relative {
	position: relative;
	width: 100%;
	padding-left: var(--xl);
	.grey_bar {
		position: absolute;
		height: 100%;
		width: 1.75rem;
		top: 0;
		left: 0;
		background-color: var(--neutral_200);
	}
}
.txt_reason {
	border-radius: 0 0 var(--md);
	padding: var(--xxxs) var(--sm);
	white-space: nowrap;
	display: inline;
	position: absolute;
	top: 0;
	left: 0px;
	z-index: 10;
}

.moto_img_soli {
	height: 6.5rem;
	object-fit: cover;
}

.bg_platinum_color {
	background-color: #6384f9;
}
.text_platinum_color {
	color: #6384f9;
}
.bg_gold_color {
	background-color: #efb810;
}
.text_gold_color {
	color: #efb810;
}
.bg_silver_color {
	background-color: #9eabb5;
}
.text_silver_color {
	color: #9eabb5;
}
.bg_bronze_color {
	background-color: #88540b;
}
.text_bronze_color {
	color: #88540b;
}
.bg_wood_color {
	background-color: #312f24;
}
.text_wood_color {
	color: #312f24;
}
