.status_arrows {
	height: 3.5rem;
	width: 3.5rem;
	&.green {
		fill: var(--green_300);
	}
	&.red {
		fill: var(--red_300);
	}
}
.notification {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--xs);
	.notification_icon_cont {
		padding: var(--md) 0 var(--md) var(--xs);
		margin-right: var(--xs);
		.notification_icon {
			padding: 0 var(--sm);
			border-left: 3px solid var(--neutral_0);
			display: flex;
		}
	}
}

.nowrap {
	white-space: nowrap;
}
