.document_card {
	height: 5.75rem;
	.image {
		max-width: 100%;
		min-height: 100%;
	}
}
.document_card_loaded {
	height: 10.75rem;
	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.delete_file_solicitud {
	position: absolute;
	cursor: pointer;
	z-index: 1;
	top: -10px;
	right: -10px;
	background: white;
	padding: 5px;
	border-radius: 50%;
	border: 0.5px solid var(--neutral_400);
}
