.rootL {
	display: grid;
	width: 100%;
	height: 100vh;
	grid-template-areas:
		'head head'
		'nav  main';
	grid-template-rows: auto 1fr;
	grid-template-columns: auto 1fr;
	.main {
		overflow-y: scroll;
		.container {
			height: 100%;
			max-width: 1850px;
			margin: 0 auto;
			padding: var(--lg);
		}
	}
}

.body_listing {
	padding-left: 4.5rem;
	padding-right: 4.5rem;
}
