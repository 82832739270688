.filters_export {
	justify-content: space-between;
	flex-wrap: wrap;
	& > * {
		max-width: 49%;
		flex: 0 0 49%;
	}
}
.cards_photo {
	width: 80px;
	height: 80px;
	&.big {
		height: 160px;
		width: 160px;
	}
}

.input-file {
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
	cursor: pointer;
	opacity: 0;
}

.pdf_name {
	width: calc(var(--xl) + var(--xxxl) + var(--xs));
	max-height: var(--xl);
	overflow: hidden;
	text-overflow: ellipsis;
}
.delete_photo {
	position: absolute;
	right: var(--xxs);
	top: var(--xxs);
	cursor: pointer;
	z-index: 1;
}

.input_indicators {
	position: relative;
	margin-bottom: var(--xs);
	.dso_indicator_left {
		position: absolute;
		left: var(--sm);
		bottom: calc(var(--md) + var(--sm) / 2);
		transform: translateY(50%);
	}
	.dso_indicator_right {
		position: absolute;
		right: var(--sm);
		bottom: calc(var(--md) + var(--sm) / 2);
		transform: translateY(50%);
	}
	.dso_input_title,
	.dso_input_subtitle {
		margin: 0;
	}
}
.grid_export {
	max-height: 90%;
	overflow-y: scroll;
}
.other_input {
	margin-top: calc(-1 * var(--xl));
}

.profile_image {
	overflow: hidden;
	border-radius: 50%;
	background-color: var(--neutral_300);
	img {
		min-height: var(--xxxl);
		min-width: var(--xxxl);
		height: 0;
		width: 0;
	}
}
