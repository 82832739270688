.photo_ozoner {
	height: 5rem;
	width: 5rem;
}

.filters {
	gap: var(--sm);
	& > * {
		flex: 0 0 8.75rem;
	}
}

.filters_export {
	justify-content: space-between;
	flex-wrap: wrap;
	& > * {
		max-width: 49%;
		flex: 0 0 49%;
	}
}

.input-file {
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
	cursor: pointer;
	opacity: 0;
}

.pdf_name {
	width: calc(var(--xl) + var(--xxxl) + var(--xs));
	max-height: var(--xl);
	overflow: hidden;
	text-overflow: ellipsis;
}

.input_indicators {
	position: relative;
	margin-bottom: var(--xs);
	.dso_indicator_left {
		position: absolute;
		left: var(--sm);
		bottom: calc(var(--md) + var(--sm) / 2);
		transform: translateY(50%);
	}
	.dso_indicator_right {
		position: absolute;
		right: var(--sm);
		bottom: calc(var(--md) + var(--sm) / 2);
		transform: translateY(50%);
	}
	.dso_input_title,
	.dso_input_subtitle {
		margin: 0;
	}
}
.grid_export {
	max-height: 90%;
	overflow-y: scroll;
}
.other_input {
	margin-top: calc(-1 * var(--xl));
}
.img_ozoner {
	&.big {
		height: 7.5rem;
		width: 7.5rem;
		border-width: var(--xs);
		border-style: solid;
	}
	&.mini {
		width: 100% !important;
		height: 100% !important;
		position: relative;
	}
	.cards_photo {
		min-height: 100%;
		height: 0;
		min-width: 100%;
	}
}

.vehicle_img {
	height: 4.25rem;
}

.details > * {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
}
.agreement_box {
	height: 7.5rem;
	border: 0;
	outline: 0;
	box-shadow: var(--shadow_medium);
	border-radius: var(--xxs);
}
.comprobante_dropzone {
	background-color: var(--primary_50);
	border-radius: var(--md);
	border: var(--primary_300) dotted;
}

.agreement_flag {
	position: absolute;
	top: 0;
	left: 0;
	padding: var(--xxxs) var(--md);
	border-end-end-radius: var(--md);
	&.accomplished {
		background-color: var(--primary_300);
	}
	&.failed {
		background-color: var(--red_300);
	}
}

.agreement_details {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1;
	top: 0;
	left: 0;
	& > * {
		flex-basis: 50%;
	}
}

.truora_trigger {
	background-color: #3067c7;
}
.palenca_trigger {
	background-color: #22d16c;
}

.img_options {
	width: 8.25rem;
}

.status_tab {
	border-bottom: 2px solid var(--neutral_300);
	white-space: nowrap;
	&.active {
		border-color: var(--primary_300);
	}
}

.cards_knob {
	width: 100%;
	.cards_text {
		flex-basis: 0;
		flex-grow: 1.5;
	}
	.card_knob {
		flex-basis: 0;
		flex-grow: 1;
		width: 100%;
	}
}

.btn_right {
	float: right;
	margin-right: 10px;
}
.btn_center {
	margin: 0 auto;
	display: block;
}

.btn_solicitud_orange_text {
	margin-left: 20px;
	.dso_btn_text {
		font-size: 14px !important;
		font-weight: 800;
	}
}

.btn_restaurar_solicitud {
	position: relative;
	top: 12px;
}

.observaciones {
	height: 6.5rem;
	border: 0;
	outline: 0;
	box-shadow: var(--shadow_medium);
	border-radius: var(--xxs);
}

.edit-button {
	position: absolute !important;
	right: 0;
	top: 0;
	transform: translate(60%, -60%);
}

.visible_filter {
	position: absolute;
	top: var(--xxxs);
	right: var(--xxl);
}

.ozoner_tab {
	margin-bottom: -1px;
	z-index: 10;
	&.active {
		background-color: var(--neutral_100);
		border: 1px solid var(--neutral_400);
		border-bottom: none;
		color: var(--primary_300);
	}
}
.reference_tab {
	position: relative;
	margin-bottom: -1px;
	z-index: 10;
	background-color: var(--neutral_100);
	border: 1px solid var(--neutral_400);
	border-bottom: none;
	&.active {
		color: var(--primary_300);
	}
}

.foto_moto {
	height: 4.5rem;
	width: 6rem;
	border: 1px solid var(--neutral_300);
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
.messages_cont {
	stroke-width: 0.5px;
	.messages_item {
		&:hover {
			.messages_title {
				display: block;
			}
		}
		.messages_title {
			position: absolute;
			bottom: 0;
			transform: translate(-50%, 50%);
			left: 50%;
			display: none;
		}
	}
}

.indicator_line {
	width: 3.25rem;
	height: 2px;
}

.cont_ozoner_agree {
	border-bottom: 0.5px var(--neutal_400) solid;
	.dso_card_img {
		border-radius: var(--xxs);
		flex-shrink: 0;
	}
}
.inventory_link:hover {
	color: var(--primary_300);
	text-decoration: underline;
}

.financial_chats {
	border-bottom: 1px solid var(--neutral_400);
}

.text_no_break {
	white-space: nowrap;
}

.edit-button-deposit {
	position: absolute !important;
	right: var(--md);
	top: 50%;
	transform: translateY(-50%);
}

.open_credits {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: var(--md);
	transition: 0.5s;
	&.open {
		transform: translateY(-50%) rotate(180deg);
	}
}

.credits_section {
	height: 0;
	position: absolute;
	min-width: 100%;
	top: 100%;
	right: 0;
	transition: 0.5s;
	overflow: hidden;
	z-index: 20;

	&.open {
		height: unset;
	}
}

.selectable_icon {
	padding: var(--xs);
	border-radius: var(--xxs);
	border: 1px solid var(--neutral_600);
	fill: var(--neutral_600);
	display: flex;
	&.active {
		border-color: var(--primary_300);
		fill: var(--primary_300);
	}
}

.date_select_ended {
	margin-bottom: 25rem;
}
