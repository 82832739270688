.delete_photo {
	position: absolute;
	right: var(--sm);
	top: var(--sm);
	cursor: pointer;
}

.percentage-symbol {
	content: '%';
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
}

.vehicle_list_img {
	width: 4.14656rem;
	height: 2.46875rem;
	.img {
		object-fit: cover;
	}
}
