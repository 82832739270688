.rootL-mobile {
	display: grid;
	width: 100%;
	height: 100vh;
	grid-template-areas:
		'head'
		'main';
	grid-template-rows: auto 1fr;
	.main {
		overflow-y: scroll;
		.mobile-container {
			min-height: 100%;
			width: 100%;
			padding: var(--lg);
			background-color: var(--neutral_300);
		}
	}
}

.body_listing {
	padding-left: 4.5rem;
	padding-right: 4.5rem;
}
