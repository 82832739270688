.login_card {
	width: 25.5rem;
	.logo {
		height: 5.75rem;
	}
}
.loading {
	width: 1.25rem !important;
	height: 1.25rem !important;
	color: white !important;
}
