.root {
	width: 100vw;
	height: 100vh;
}

.img1 {
	z-index: -1;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 38rem;
	height: 33rem;
}

.img2 {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 42rem;
	height: 41rem;
}

.login_card {
	width: 25.5rem;
	.logo {
		height: 5.75rem;
	}
}
.loading {
	width: 1.25rem !important;
	height: 1.25rem !important;
	color: white !important;
}
