.table_divider {
	height: 100%;
	border: 0.5px var(--neutral_400) solid;
}
.recovery_filters {
	gap: var(--sm);
	& > * {
		flex-basis: 8.75rem;
	}
}
.map_container {
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
}

.main_marker {
	position: relative;
	bottom: 100%;
	transform: translateY(-0.5rem);
	.arrow {
		position: absolute;
		background-color: var(--neutral_100);
		width: var(--md);
		height: var(--md);
		top: 85%;
		transform: rotate(45deg);
		z-index: 0;
	}
}

.action_option:hover {
	background-color: var(--primary_100);
}

.details_container {
	position: absolute;
	height: 100%;
	width: 50%;
	padding: var(--md);
	display: flex;
	gap: var(--md);
	align-items: start;
	transition: 1s;
	left: -100vw;
	&.open {
		left: 0px;
	}
}

.recovery_photo {
	height: 3.75rem;
	width: 3.75rem;
}

.title_mark {
	top: calc(100% + var(--xxs));
	left: 0;
}
.img_recovery_cont {
	height: 15rem;
}
.loader_map {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	flex-direction: column;
	z-index: 100;
}
.map_conventions_bar {
	position: absolute;
	left: var(--lg);
	top: var(--lg);
	z-index: 10;
}
.map_search_bar {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
	display: flex;
	background-color: var(--neutral_0);
	align-items: center;
	transition: 0.5s;
	.open_icon {
		transition: 0.3s;
		transform: rotate(0deg);
	}
	.seach_input {
		transition: 0.3s;
		width: 0;
		overflow: hidden;
		margin-left: 0;
		&.dso_input_error {
			input::placeholder {
				color: var(--red_300);
			}
		}
		.dso_input_title {
			margin-bottom: 0;
		}
		.dso_input_subtitle {
			margin-top: 0;
		}
	}
	&.open {
		.seach_input {
			width: 24rem;
			margin-left: var(--md);
		}
		.open_icon {
			transform: rotate(180deg);
		}
	}
}

.map_route {
	position: absolute;
	right: var(--xs);
	bottom: var(--xs);
	z-index: 10;
	transition: 0.5s;
	width: 28rem;
	.route_bar {
		width: 100%;
		display: flex;
		background-color: var(--neutral_0);
		align-items: center;
	}
	.open_icon {
		transition: 0.3s;
		transform: rotate(90deg);
	}
	.route_cont {
		height: 0;
		overflow: hidden;
		transition: inherit;
	}
	&.open {
		width: 45rem;
		.route_bar {
			border-bottom: 1px solid var(--neutral_300);
		}
		.open_icon {
			transform: rotate(270deg);
		}
		.route_cont {
			height: 45vh;
		}
	}

	.route_component {
		overflow: hidden;
		.open_details_icon {
			transition: 0.3s;
		}
		.route_component_details {
			display: none;
		}
		&.open {
			.route_component_details {
				display: block;
			}
			.open_details_icon {
				fill: var(--primary_300);
				transform: rotate(180deg);
			}
		}
	}
}

.canal_drag {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		height: 100%;
		width: 1px;
		background-color: var(--neutral_500);
		top: 0px;
		right: -20px;
	}
}

.policy_select {
	min-width: 12rem;
}
