.table_mini {
	.flex_body {
		position: relative;
		left: 25px;
	}
}
.btn_search_mini {
	position: relative;
	bottom: 57px;
	left: 81%;
	height: 40px;
	.dso_btn_cont {
		margin-bottom: 10px;
		margin-top: 5px;
		svg {
			position: relative;
			left: 8px;
		}
	}
}
.align_left_btn_credits {
	.dso_btn_cont {
		width: inherit !important;
		text-align: justify !important;
	}
}
.crdts_slider {
	padding-right: 5px;
}

.big {
	width: 5rem;
	height: 5rem;
}

.credit_table_status {
	width: 8.05rem;
}
