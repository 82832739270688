.navbar {
	z-index: 11;
	background-color: var(--neutral_0);
	grid-area: head;
	position: relative;
	display: flex;
	padding: var(--md) var(--xl);
	justify-content: space-between;
}
.menu_selectable {
	cursor: pointer;
	white-space: nowrap;
	&:hover {
		background-color: var(--primary_100);
	}
	&.active {
		background-color: var(--primary_200);
	}
}

.indicator_unread {
	top: 0;
	right: 0;
	transform: translate(-50%, -50%);
}

.notification_popup {
	width: 35rem;
	border: solid 1px var(--neutral_400);
	max-height: 75vh;
	overflow-y: scroll;
}
