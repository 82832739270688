.sidebar {
	z-index: 10;
	position: relative;
	width: 16rem;
	grid-area: nav;
	transition: width 0.25s;
	&.close {
		width: 4.5rem;
		.containerSelect {
			justify-content: center;
			padding: 0;
			&:hover .link_icon {
				margin-left: 0;
			}
			.link_text {
				visibility: hidden;
				display: none;
			}
			.link_icon {
				margin-right: 0;
			}
		}
	}
	.circle {
		z-index: 40;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		border-radius: 50%;
		cursor: pointer;
		width: var(--xxl);
		height: var(--xxl);
		align-items: center;
		justify-content: center;
		display: flex;
		img {
			width: var(--md);
			height: var(--md);
		}
	}
	.containerSelect {
		z-index: 40;
		width: 100%;
		height: var(--xxxl);
		display: flex;
		justify-content: flex-start;
		padding: 0 var(--xl);
		align-items: center;
		cursor: pointer;
		position: relative;
		transition: padding 0.25s;
		&.active {
			background-color: var(--primary_300);
			border-radius: var(--xxxl);
			.link_icon,
			.link_text {
				color: var(--neutral_0) !important;
				fill: var(--neutral_0) !important;
			}
		}
		&:hover .link_icon {
			margin-left: var(--md);
		}
		.link_text {
			visibility: visible;
		}
		.link_icon {
			margin-right: var(--md);
		}
	}
}
