.navbar-mobile {
	z-index: 11;
	background-color: var(--neutral_0);
	grid-area: head;
	position: relative;
	display: flex;
	padding: var(--md) var(--xl);
}
.menu_selectable {
	cursor: pointer;
	white-space: nowrap;
	&:hover {
		background-color: var(--primary_100);
	}
	&.active {
		background-color: var(--primary_200);
	}
}

.profile_img {
	right: var(--md);
	top: 50%;
	transform: translateY(-50%);
}
