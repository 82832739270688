.discount-container {
	display: flex;
	flex-direction: column;
	height: 100%;

	form {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	& .title-discount-container {
		display: flex;
		justify-content: center;
	}

	& .footer-discount-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	& .discount-rate {
		display: flex;
		border-radius: 0 8px;
		height: 28px;
		width: 62px;
		align-items: center;
	}
}
