* {
	box-sizing: border-box;
	font-family: Poppins;
}
body {
	background-color: var(--neutral_100) !important;
}
.custom_pointer {
	cursor: pointer;
}
.modal-close-btn {
	margin-top: -20px;
	float: right;
	cursor: pointer;
}
.modal-close-btn:hover {
	color: lightgray;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #fe8a02;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

* {
	&::-webkit-scrollbar {
		height: 0.3rem;
		width: 0.3rem;
		background-color: var(--neutral_300);
		border-radius: var(--xs);
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--primary_300);
		border-radius: var(--xs);
	}
	&::-webkit-scrollbar-button {
		width: 0;
		height: 0;
	}
}

.MuiOutlinedInput-input {
	padding: 12px 1px !important;
}

.MuiOutlinedInput-adornedEnd {
	padding-right: 3px !important;
}
.MuiGrid-container.spaced {
	box-sizing: content-box !important;

	padding-right: 24px;
	padding-bottom: 24px;
}
//.MuiBackdrop-root {
//  background-color: rgba(0, 0, 0, 0.2) !important;
//}

.inner_container {
	box-shadow: var(--shadow_hard);
	border: 1px var(--neutral_300) solid;
	border-radius: var(--sm);
	overflow: hidden;
}
.text-hover {
	text-decoration: underline;
	cursor: pointer;
}

.loading-map {
	position: fixed;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	height: 100vh;
	width: 100vw;
}

.search-container {
	position: fixed;
	top: 20px;
	left: 20px;
	background-color: white;
	z-index: 999;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
	width: 250px;
	padding: 5px;
	border-radius: 5px;
}

.pointer {
	cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
