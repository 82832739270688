.alarms_section {
	max-height: 40rem;
	overflow-y: auto;
}
.detailsView {
	.add_icon {
		position: absolute;
		right: 0;
	}
	.image-section {
		position: relative;
		overflow: hidden;
		width: 100%;
		display: flex;
		gap: var(--md);
		.image-cont {
			flex-basis: 0;
			flex-grow: 1;
			overflow: hidden;
			cursor: pointer;
			transition: 0.25s;
			border: 2px solid transparent;
			&.active,
			&:hover {
				border-color: var(--primary_300);
			}
			&.empty {
				pointer-events: none;
			}
			.image-basis {
				padding-top: 100%;
				position: relative;
				overflow: hidden;
				width: 100%;
				img {
					height: 100%;
					position: absolute;
				}
				.cover_img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
		}
		// }
	}
	.detail_data {
		border: 1px var(--neutral_300) solid;
		border-width: 1px 1px 0;
		& > *:first-child {
			border-right: 1px var(--neutral_300) solid;
		}
	}
	.final_detail {
		border-top: 1px var(--neutral_300) solid;
	}
	.swiper_img {
		height: 20rem;
	}
	.status_card {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		position: relative;
	}

	.card_eye {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.swiper-button-prev,
	.swiper-button-next {
		outline: 0;
		transition: 0.25s;
		transform: scale(1);
		&:hover {
			transform: scale(1.1);
		}
		&::after {
			height: 100%;
		}
	}
	.swiper-button-prev::after {
		content: url(~design_system/src/static/icons/left-arrow.svg);
	}
	.swiper-button-next::after {
		content: url(~design_system/src/static/icons/right-arrow.svg);
	}
	.static_info_vehicle {
		max-height: 100%;
		overflow-y: auto;
	}
}

.detalles_edit {
	margin-top: -1rem;
}
