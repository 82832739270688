.inventory_card {
  &:hover {
    border: 1px solid var(--primary_300);
  }
  .fix{
    flex:0 0 auto;
  }
}
.moto_img{
  max-height: 6.5rem;
  object-fit: cover;
}


